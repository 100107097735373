import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_5 = { for: "name" }
const _hoisted_6 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_7 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_8 = { for: "surname" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_11 = { for: "gender" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_14 = { for: "birthdate" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "p-field p-col-4 p-fluid p-disabled" }
const _hoisted_17 = { for: "age" }
const _hoisted_18 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_19 = { for: "phone" }
const _hoisted_20 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_21 = { for: "address" }
const _hoisted_22 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_23 = { for: "postalCode" }
const _hoisted_24 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_25 = { for: "city" }
const _hoisted_26 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_27 = { for: "country" }
const _hoisted_28 = ["aria-label"]
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "p-grid" }
const _hoisted_31 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_32 = { for: "email" }
const _hoisted_33 = {
  key: 0,
  class: "p-invalid"
}

import dateFormat from '@/helpers/DateFormat.helper';
import { Genders } from '@/models/Genders';
import Patient, { PatientInformationFormDto, updatePatientWithPatientsInformation } from '@/models/Patient';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import {
  isPatientContactValid,
  isPatientInfoProfileValid,
  patientContactForm,
  patientProfileForm,
} from '@/validation/patientForm';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import CardComponent from '../CardComponent.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientData',
  setup(__props) {

const toast = useToast();
const { t } = useI18n();
const route = useRoute();
const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;

const patient = ref();
const patientInfo = ref<PatientInformationFormDto>({} as PatientInformationFormDto);

const editMode = ref(false);
const emailEditMode = ref(false);
const waitSubmit = ref(false);
const showValidation = ref(false);
const validatedPatientProfileForm = computed(() => patientProfileForm(patientInfo.value));
const validatedPatientContactForm = computed(() => patientContactForm(patientInfo.value));
const patientProfileValid = computed(() => isPatientInfoProfileValid(validatedPatientProfileForm.value));
const patientContactValid = computed(() => isPatientContactValid(validatedPatientContactForm.value));

const minRangeYearBirth = ref('1920');
const maxRangeYearBirth = ref(new Date().getFullYear());

const genderOptions = ref([
  { label: t(`user.${Genders.Female}`), value: Genders.Female },
  { label: t(`user.${Genders.Male}`), value: Genders.Male },
]);

const age = computed(() => dateFormat.calculateAge(patientInfo.value.birthdate));

onMounted(async () => {
  await loadPatient();
});

const loadPatient = async () => {
  const result = await patientService.find(patientId);
  if (!(result instanceof ServiceError)) {
    patient.value = result;
    patientInfo.value = new PatientInformationFormDto(result);
  }
};

const onSave = async () => {
  showValidation.value = true;
  if (!patientProfileValid.value || !patientContactValid.value) {
    return;
  }

  await savePatient(updatePatientWithPatientsInformation(patient.value, patientInfo.value));
};

const onSaveEmail = async () => {
  showValidation.value = true;
  if (!patientProfileValid.value || !patientContactValid.value) {
    return;
  }

  await savePatientEmail(updatePatientWithPatientsInformation(patient.value, patientInfo.value));
};

async function savePatient(patient: Patient) {
  waitSubmit.value = true;
  const result = await patientService.modify(patient.id, patient);

  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    editMode.value = false;
    showValidation.value = false;
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
  }
  waitSubmit.value = false;
}

async function savePatientEmail(patient: Patient) {
  waitSubmit.value = true;
  const result = await patientService.modifyEmail(patient.id, patient.email);

  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    emailEditMode.value = false;
    showValidation.value = false;
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
  }
  waitSubmit.value = false;
}

const onCancelEdition = async () => {
  editMode.value = false;
  patientInfo.value = Object.assign({}, new PatientInformationFormDto(patient.value));
};

const onEmailCancelEdition = async () => {
  emailEditMode.value = false;
  patientInfo.value = Object.assign({}, new PatientInformationFormDto(patient.value));
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CardComponent, {
      cardTitle: _ctx.$t('configuration.patientData'),
      "edit-mode": editMode.value
    }, {
      headingActions: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_Button, {
          class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", editMode.value ? 'p-disabled' : '']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (editMode.value = !editMode.value))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["class"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      cardContent: _withCtx(() => [
        (patientInfo.value)
          ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('person.name')) + " " + _toDisplayString(editMode.value ? '*' : ''), 1),
                  _createVNode(HealzInput, {
                    id: "name",
                    modelValue: patientInfo.value.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((patientInfo.value.name) = $event)),
                    modelModifiers: { trim: true },
                    editable: editMode.value,
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedPatientProfileForm.value.name.valid })
                  }, null, 8, ["modelValue", "editable", "class"]),
                  (showValidation.value && !validatedPatientProfileForm.value.name.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t(`${validatedPatientProfileForm.value.name.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('person.surname')) + " " + _toDisplayString(editMode.value ? '*' : ''), 1),
                  _createVNode(HealzInput, {
                    id: "surname",
                    modelValue: patientInfo.value.surname,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((patientInfo.value.surname) = $event)),
                    modelModifiers: { trim: true },
                    editable: editMode.value,
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedPatientProfileForm.value.surname.valid })
                  }, null, 8, ["modelValue", "editable", "class"]),
                  (showValidation.value && !validatedPatientProfileForm.value.surname.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${validatedPatientProfileForm.value.surname.message}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('patient.sex')), 1),
                  (!patientInfo.value.gender && !editMode.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, "-"))
                    : (_openBlock(), _createBlock(_component_Dropdown, {
                        key: 1,
                        id: "gender",
                        modelValue: patientInfo.value.gender,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((patientInfo.value.gender) = $event)),
                        options: genderOptions.value,
                        "option-label": "label",
                        "option-value": "value",
                        class: _normalizeClass(editMode.value ? '' : 'no-editable')
                      }, null, 8, ["modelValue", "options", "class"]))
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('person.birthdate')), 1),
                  (!patientInfo.value.birthdate && !editMode.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, "-"))
                    : (_openBlock(), _createBlock(_component_Calendar, {
                        key: 1,
                        id: "birthdate",
                        modelValue: patientInfo.value.birthdate,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((patientInfo.value.birthdate) = $event)),
                        "year-navigator": true,
                        "year-range": `${minRangeYearBirth.value}:${maxRangeYearBirth.value}`,
                        class: _normalizeClass(editMode.value ? '' : 'no-editable')
                      }, null, 8, ["modelValue", "year-range", "class"]))
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('person.age')), 1),
                  _createVNode(HealzInput, {
                    id: "age",
                    modelValue: age.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((age).value = $event)),
                    class: "p-disabled",
                    editable: editMode.value
                  }, null, 8, ["modelValue", "editable"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('person.phone')), 1),
                  _createVNode(HealzInput, {
                    id: "phone",
                    modelValue: patientInfo.value.phoneNumber,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((patientInfo.value.phoneNumber) = $event)),
                    modelModifiers: { number: true },
                    type: "tel",
                    editable: editMode.value
                  }, null, 8, ["modelValue", "editable"])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('person.address')), 1),
                  _createVNode(HealzInput, {
                    id: "address",
                    modelValue: patientInfo.value.address,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((patientInfo.value.address) = $event)),
                    modelModifiers: { trim: true },
                    editable: editMode.value
                  }, null, 8, ["modelValue", "editable"])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('person.postalCode')), 1),
                  _createVNode(HealzInput, {
                    id: "postalCode",
                    modelValue: patientInfo.value.postalCode,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((patientInfo.value.postalCode) = $event)),
                    editable: editMode.value
                  }, null, 8, ["modelValue", "editable"])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('person.city')), 1),
                  _createVNode(HealzInput, {
                    id: "city",
                    modelValue: patientInfo.value.city,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((patientInfo.value.city) = $event)),
                    modelModifiers: { trim: true },
                    editable: editMode.value
                  }, null, 8, ["modelValue", "editable"])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('person.country')), 1),
                  _createVNode(HealzInput, {
                    id: "country",
                    modelValue: patientInfo.value.country,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((patientInfo.value.country) = $event)),
                    modelModifiers: { trim: true },
                    editable: editMode.value
                  }, null, 8, ["modelValue", "editable"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      footerActions: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-secondary p-button-medium",
          label: _ctx.$t('common.cancel'),
          onClick: onCancelEdition
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button p-button-info p-button-medium",
          loading: waitSubmit.value,
          label: _ctx.$t('common.save'),
          onClick: onSave
        }, null, 8, ["loading", "label"])
      ]),
      _: 1
    }, 8, ["cardTitle", "edit-mode"]),
    _createVNode(CardComponent, {
      cardTitle: _ctx.$t('configuration.otherData'),
      "edit-mode": emailEditMode.value
    }, {
      headingActions: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_Button, {
          class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", emailEditMode.value ? 'p-disabled' : '']),
          onClick: _cache[11] || (_cache[11] = ($event: any) => (emailEditMode.value = !emailEditMode.value))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_28)
          ]),
          _: 1
        }, 8, ["class"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      cardContent: _withCtx(() => [
        (patientInfo.value)
          ? (_openBlock(), _createElementBlock("form", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('person.email')) + " " + _toDisplayString(emailEditMode.value ? '*' : ''), 1),
                  _createVNode(HealzInput, {
                    id: "email",
                    modelValue: patientInfo.value.email,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((patientInfo.value.email) = $event)),
                    modelModifiers: { trim: true },
                    type: "email",
                    class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedPatientContactForm.value.email.valid }),
                    editable: emailEditMode.value
                  }, null, 8, ["modelValue", "class", "editable"]),
                  (showValidation.value && !validatedPatientContactForm.value.email.valid)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_33, _toDisplayString(_ctx.$t(`${validatedPatientContactForm.value.email.message}`)), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      footerActions: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-secondary p-button-medium",
          label: _ctx.$t('common.cancel'),
          onClick: onEmailCancelEdition
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button p-button-info p-button-medium",
          loading: waitSubmit.value,
          label: _ctx.$t('common.save'),
          onClick: onSaveEmail
        }, null, 8, ["loading", "label"])
      ]),
      _: 1
    }, 8, ["cardTitle", "edit-mode"])
  ], 64))
}
}

})