
import EmptyState from '@/components/EmptyState.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import AlternativeMealModal from '@/components/nutrition/menus/AlternativeMealModal.vue';
import MealFoodsCard from '@/components/nutrition/menus/MealFoodsCard/MealFoodsCard.vue';
import {
  MenuMealDto,
  NutritionalPlan,
  NutritionalPlanFormDto,
  updateMenuMealFromMenuMealDto,
} from '@/models/NutritionalPlan';
import { Patient } from '@/models/Patient';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import NutritionalPlanDeleteModal from './NutritionalPlanDeleteModal.vue';
import NutritionalPlanHeader from './NutritionalPlanHeader.vue';
import NutritionalPlanTotalsTable from './NutritionalPlanTotalsTable.vue';

export default defineComponent({
  components: {
    LoadingComponent,
    NutritionalPlanHeader,
    NutritionalPlanTotalsTable,
    NutritionalPlanDeleteModal,
    MealFoodsCard,
    AlternativeMealModal,
    EmptyState,
  },
  emits: ['nutritional-plan-deleted'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const storeProfile = useProfileStore();
    const patientId = computed(() => props.patient.id);

    const tabsMenu = ref([
      { label: t(`nutrition.tabs.patientData`), to: 'patient-data' },
      { label: t(`nutrition.tabs.foodDistribution`), to: 'food-distribution' },
      { label: t(`nutrition.tabs.menuTemplate`), to: 'menu-template' },
    ]);

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const nutritionalPlanId = route.params.nutritionalPlan as string;
    const nutritionalPlan: Ref<NutritionalPlan | undefined> = ref();
    const nutritionalPlanFormDto: Ref<NutritionalPlanFormDto | undefined> = ref();

    const loadingNutritionalPlan = ref(true);
    const nutritionalPlanDeleteModalRef = ref();
    const title = computed(() => nutritionalPlan.value?.name as string);
    const breadcrumb = ref([] as Array<any>);
    const alternativeMealModalRef = ref();

    const userCanEdit = computed(() => {
      if (isAdminUser.value || isReceptionistUser.value) {
        return true;
      }

      if (!storeProfile.userId || !nutritionalPlanFormDto.value?.professional.id || !isSpecialistUser.value) {
        return false;
      }

      return nutritionalPlanFormDto.value?.professional.id === storeProfile.userId;
    });

    const totalCalories = computed(() => {
      return parseFloat(
        nutritionalPlanFormDto.value?.meals
          .reduce((totalResult, meal) => {
            return totalResult + meal.mealDetails.reduce((sum, mealDetail) => sum + mealDetail.calories, 0);
          }, 0)
          .toFixed(2) || '',
      );
    });

    const fetchNutritionalPlan = async () => {
      loadingNutritionalPlan.value = true;
      const result = await nutritionalPlanService.findById(props.patient.id, nutritionalPlanId);

      if (!(result instanceof ServiceError)) {
        breadcrumb.value = [
          { label: t('breadcrumb.plans'), to: `/patient/${props.patient.id}/nutrition/plans` },
          {
            label: result.name,
            to: '#',
            disabled: true,
          },
        ];
        nutritionalPlan.value = result;
        nutritionalPlanFormDto.value = new NutritionalPlanFormDto(result);
      }
      loadingNutritionalPlan.value = false;
    };

    const onDeleteNutritionalPlan = async () => {
      nutritionalPlanDeleteModalRef.value?.openDialog();
    };

    const onNutritionalPlanDeleted = async () => {
      router.push(`/patient/${props.patient.id}/nutrition/plans`);
    };

    const onNutritionalPlanEnabledChanged = async (enabled: boolean) => {
      if (nutritionalPlanFormDto.value) {
        nutritionalPlanFormDto.value.enabled = enabled;
        const result = await nutritionalPlanService.updateEnabled(
          props.patient.id,
          nutritionalPlanFormDto.value.id,
          enabled,
        );
        if (!(result instanceof ServiceError)) {
          nutritionalPlan.value = result;
          nutritionalPlanFormDto.value = new NutritionalPlanFormDto(result);
          toast.add({
            severity: 'success',
            summary: `${t('messages.notifications.successEditNutritionPlan')}`,
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditNutritionalPlan')}`,
            life: 3000,
          });
        }
      }
    };

    async function onMenuFoodUpdate(updatedMenuMeal: MenuMealDto) {
      console.log('updatedMenuMeal', updatedMenuMeal);
      if (nutritionalPlan.value) {
        const result = await nutritionalPlanService.updateDefaultMeal(
          patientId.value,
          nutritionalPlan.value.id,
          updatedMenuMeal.id,
          updateMenuMealFromMenuMealDto(updatedMenuMeal.mealDetails),
        );

        if (result instanceof ServiceError) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditMeal')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } else {
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditMeal')}`, life: 3000 });
        }
      }
    }

    const onCreateAlternativeMeal = async (mealId: string, mealName: string) => {
      alternativeMealModalRef.value?.openDialog(mealId, null, nutritionalPlan.value?.id, mealName);
    };

    onMounted(async () => {
      await fetchNutritionalPlan();
    });

    return {
      route,
      title,
      breadcrumb,
      tabsMenu,
      nutritionalPlan,
      nutritionalPlanFormDto,
      userCanEdit,
      patientId,
      alternativeMealModalRef,
      totalCalories,
      fetchNutritionalPlan,
      onDeleteNutritionalPlan,
      nutritionalPlanDeleteModalRef,
      loadingNutritionalPlan,
      onNutritionalPlanDeleted,
      onNutritionalPlanEnabledChanged,
      onMenuFoodUpdate,
      onCreateAlternativeMeal,
    };
  },
});
