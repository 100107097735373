import { Patient, PatientDto, PatientFormDto, PatientResume } from '@/models/Patient';
import { Chart } from '@/models/Statistics';
import { Survey, SurveyCgmInitial, SurveyType } from '@/models/Survey';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';

const storeProfile = useProfileStore();

class PatientService {
  axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async findAll(): Promise<PatientDto[] | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findDashboardPatients(specialistId?: string): Promise<PatientDto[] | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {},
    };

    if (specialistId) {
      options.params['specialistId'] = specialistId;
    }

    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/dashboard?accountId=${storeProfile.accountId}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async find(patientId: string): Promise<PatientDto | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async create(data: PatientFormDto): Promise<Patient | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.post(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data as Patient;
    } catch (error) {
      return handleError(error);
    }
  }

  async modify(patientId: string, data: Patient): Promise<Patient | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.put(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data as Patient;
    } catch (error) {
      return handleError(error);
    }
  }

  async modifyEmail(patientId: string, email: string): Promise<Patient | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.put(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}/email?accountId=${storeProfile.accountId}`,
        {
          email: email,
        },
      );
      return networkResponse.data as Patient;
    } catch (error) {
      return handleError(error);
    }
  }

  async modifySpecialists(
    patientId: string,
    specialistIds: string[],
    specialistIdToReassign?: string,
  ): Promise<Patient | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    let data: any = {
      specialistIds,
    };

    if (specialistIdToReassign) {
      data = { ...data, specialistIdToReassign };
    }

    try {
      const networkResponse = await this.axiosInstance.put(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}/specialists?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data as Patient;
    } catch (error) {
      return handleError(error);
    }
  }

  async findAllSurveys(patientId: string): Promise<Survey[] | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}/survey?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findSurvey(patientId: string, surveyId: SurveyType): Promise<Survey | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}/survey/${surveyId}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findSurveyCgmInitial(patientId: string): Promise<SurveyCgmInitial | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}/survey/${SurveyType.CGM_INITIAL}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async modifySurveyCgmInitial(patientId: string, data: SurveyCgmInitial): Promise<Survey | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.put(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}/survey?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data as SurveyCgmInitial;
    } catch (error) {
      return handleError(error);
    }
  }

  async getPatientsByProfessionalId(professionalId: string): Promise<PatientDto[]> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/professional/${professionalId}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async delete(patientId: string): Promise<Patient | ServiceError> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await this.axiosInstance.delete(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/${patientId}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data as Patient;
    } catch (error) {
      return handleError(error);
    }
  }

  async getPatientResume(specialistId?: string): Promise<PatientResume> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {},
    };

    if (specialistId) {
      options.params['specialistId'] = specialistId;
    }

    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/resume?accountId=${storeProfile.accountId}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async getPatientAnalytics(year: number, specialistId?: string): Promise<Chart> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {},
    };

    if (specialistId) {
      options.params['specialistId'] = specialistId;
    }

    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/analytics?accountId=${storeProfile.accountId}&year=${year}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async getPatientAnalyticsNutritional(year: number, specialistId?: string): Promise<Chart> {
    this.axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {},
    };

    if (specialistId) {
      options.params['specialistId'] = specialistId;
    }

    try {
      const networkResponse = await this.axiosInstance.get(
        `${process.env.VUE_APP_API_PATIENT_SERVICE}/patient/analytics/nutritional?accountId=${storeProfile.accountId}&year=${year}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      throw handleError(error);
    }
  }
}

export const patientService = new PatientService();
