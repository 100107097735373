import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-nutrition-container"
}
const _hoisted_2 = { class: "c-nutrition-meals" }
const _hoisted_3 = {
  key: 1,
  class: "c-nutrition-empty-state-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NutritionalPlanTotalsTable = _resolveComponent("NutritionalPlanTotalsTable")!
  const _component_NutritionalPlanMealsTable = _resolveComponent("NutritionalPlanMealsTable")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_ctx.nutritionalPlanFormDtoEditable.meals && _ctx.nutritionalPlanFormDtoEditable.meals.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_NutritionalPlanTotalsTable, {
          totalCalories: _ctx.totalCalories,
          nutritionalPlanFormDto: _ctx.nutritionalPlanFormDto,
          defaultMeals: _ctx.nutritionalPlan?.defaultMeals
        }, null, 8, ["totalCalories", "nutritionalPlanFormDto", "defaultMeals"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nutritionalPlanFormDto.meals, (meal, index) => {
            return (_openBlock(), _createBlock(_component_NutritionalPlanMealsTable, {
              key: index,
              mealIndex: index,
              meal: meal,
              totalCalories: _ctx.totalCalories,
              userCanEdit: _ctx.userCanEdit,
              nutritionalPlanFormDto: _ctx.nutritionalPlanFormDto,
              onUpdatedNutritionalPlan: _ctx.updatedNutritionalPlan
            }, null, 8, ["mealIndex", "meal", "totalCalories", "userCanEdit", "nutritionalPlanFormDto", "onUpdatedNutritionalPlan"]))
          }), 128))
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_EmptyState, {
          heading: _ctx.$t('nutrition.foodDistribution.no-data'),
          text: _ctx.$t('nutrition.foodDistribution.complete-patient-data'),
          secondary: false,
          icon: "icon-file_blank_outline"
        }, null, 8, ["heading", "text"])
      ]))
}