
import { Meal, NutritionalPlan, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { PropType, Ref, computed, defineComponent, ref } from 'vue';
import EmptyState from '../../EmptyState.vue';
import { useProfileStore } from '@/store/profile.module';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useRoute } from 'vue-router';
import NutritionalPlanMealsTable from './NutritionalPlanMealsTable.vue';
import NutritionalPlanTotalsTable from './NutritionalPlanTotalsTable.vue';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { EmptyState, NutritionalPlanMealsTable, NutritionalPlanTotalsTable },
  emits: ['nutritional-plan-changed'],
  props: {
    nutritionalPlanFormDto: {
      type: Object as PropType<NutritionalPlanFormDto>,
      required: true,
    },
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlan>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const storeProfile = useProfileStore();
    const route = useRoute();
    const toast = useToast();

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const nutritionalPlanFormDtoEditable: Ref<NutritionalPlanFormDto> = ref(props.nutritionalPlanFormDto);

    const totalCalories = computed(() => {
      return parseFloat(
        nutritionalPlanFormDtoEditable.value.meals
          .reduce((totalResult, meal) => {
            return totalResult + meal.mealDetails.reduce((sum, mealDetail) => sum + mealDetail.calories, 0);
          }, 0)
          .toFixed(2),
      );
    });

    const totalProtein = computed(() => nutritionalPlanFormDtoEditable.value.proteinShare.toFixed(2));
    const totalCarbs = computed(() => nutritionalPlanFormDtoEditable.value.carbohydratesShare.toFixed(2));
    const totalFat = computed(() => nutritionalPlanFormDtoEditable.value.fatShare.toFixed(2));

    const patientId = route.params.patientId as string;

    const onUpdateMeal = async (meal: Meal, key: number): Promise<void> => {
      const targetMeal = nutritionalPlanFormDtoEditable.value.meals[key];

      if (targetMeal) {
        targetMeal.mealDetails = meal.mealDetails;
        updateMeal(meal);
      }
    };

    const updateMeal = async (meal: Meal) => {
      const result = await nutritionalPlanService.updateNutritionalPlanMeal(
        patientId,
        props.nutritionalPlanFormDto.id,
        meal.mealType,
        meal,
      );

      if (result instanceof ServiceError) {
        toast.add({ severity: 'error', summary: t(`messages.error.${result.code}`), life: 3000 });
        return false;
      } else {
        nutritionalPlanFormDtoEditable.value = new NutritionalPlanFormDto(result);
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successEditNutritionPlan')}`,
          life: 3000,
        });
        return true;
      }
    };

    const userCanEdit = computed(() => {
      return isAdminUser.value || isReceptionistUser.value || isSpecialistUser.value;
    });

    const updatedNutritionalPlan = (nutritionalPlanUpdated: NutritionalPlan) => {
      nutritionalPlanFormDtoEditable.value = new NutritionalPlanFormDto(nutritionalPlanUpdated);

      toast.add({
        severity: 'success',
        summary: `${t('messages.notifications.successEditNutritionPlan')}`,
        life: 3000,
      });

      emit('nutritional-plan-changed', nutritionalPlanUpdated);
    };

    return {
      totalCalories,
      totalProtein,
      totalCarbs,
      totalFat,
      userCanEdit,
      onUpdateMeal,
      nutritionalPlanFormDtoEditable,
      updatedNutritionalPlan,
    };
  },
});
