import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-12" }
const _hoisted_3 = { class: "dropdown-food-macros" }
const _hoisted_4 = {
  key: 0,
  class: "item-macro"
}
const _hoisted_5 = {
  key: 1,
  class: "item-macro"
}
const _hoisted_6 = {
  key: 2,
  class: "item-macro"
}

import { Food, FoodCategory, MenuMealDetailDto } from '@/models/NutritionalPlan';
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import { computed, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownWithFoodSearch',
  props: {
  food: {
    type: Object as PropType<MenuMealDetailDto>,
    required: true,
  },
  foodsOptions: {
    type: Array as PropType<Food[]>,
    required: true,
  },
},
  emits: ['on-food-change'],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const props = __props;

const { t } = useI18n();
const op = ref();
const selectedFood = ref();

onMounted(() => {
  selectedFood.value = props.foodsOptions.filter((f) => f.id === props.food.foodId).at(0);
});

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  category: { value: [], matchMode: FilterMatchMode.IN },
});

const toggle = (event: any) => {
  op.value.toggle(event);
};

const onProductSelect = (event: any) => {
  op.value.hide();
  const food = event.data;
  if (food) {
    emits('on-food-change', food);
  }
};

const categoriesOptions = computed(() => {
  return Object.keys(FoodCategory)
    .map((key) => ({
      label: t(`food.categories.${key}`),
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Dropdown), {
      modelValue: selectedFood.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedFood).value = $event)),
      options: __props.foodsOptions,
      optionLabel: "name",
      type: "button",
      class: "fake-dropdown",
      label: __props.food ? __props.food.food.name : 'Select a Product',
      "aria-haspopup": "true",
      "aria-controls": "overlay_panel",
      panelStyle: { display: 'none !important' },
      onClick: toggle
    }, null, 8, ["modelValue", "options", "label"]),
    _createVNode(_component_OverlayPanel, {
      ref_key: "op",
      ref: op,
      appendTo: "self",
      showCloseIcon: true,
      dismissable: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          filters: filters.value,
          "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((filters).value = $event)),
          selection: selectedFood.value,
          "onUpdate:selection": _cache[3] || (_cache[3] = ($event: any) => ((selectedFood).value = $event)),
          value: __props.foodsOptions,
          selectionMode: "single",
          "sort-field": "name",
          "sort-order": 1,
          "removable-sort": "",
          "filter-display": "menu",
          paginator: true,
          rows: 10,
          class: "overlay-foods-table",
          onRowSelect: _cache[4] || (_cache[4] = (e) => onProductSelect(e))
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, [
                _cache[5] || (_cache[5] = _createElementVNode("i", {
                  class: "icon-search",
                  "aria-hidden": "true"
                }, null, -1)),
                _createVNode(_component_InputText, {
                  modelValue: filters.value['name'].value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filters.value['name'].value) = $event)),
                  placeholder: _ctx.$t('table.search.default')
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ])
          ]),
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('table.not-found.foods')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "name",
              header: _ctx.$t('food.name'),
              sortable: ""
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "category",
              "filter-field": "category",
              header: _ctx.$t('food.category'),
              showClearButton: false,
              showApplyButton: false,
              showFilterMatchModes: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.$t(`food.categories.${data.category}`)), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_MultiSelect, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: categoriesOptions.value,
                  placeholder: _ctx.$t('food.filterCategory'),
                  "option-label": "label",
                  "option-value": "value",
                  class: "p-column-filter",
                  onChange: ($event: any) => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { field: "macros" }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_3, [
                  (data.proteinBites)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _cache[6] || (_cache[6] = _createElementVNode("i", {
                          class: "icon-proteins icon-macro",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(data.proteinBites), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (data.carbohydratesBites)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _cache[7] || (_cache[7] = _createElementVNode("i", {
                          class: "icon-hc icon-macro",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(data.carbohydratesBites), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (data.fatBites)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _cache[8] || (_cache[8] = _createElementVNode("i", {
                          class: "icon-fats icon-macro",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(data.fatBites), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["filters", "selection", "value"])
      ]),
      _: 1
    }, 512)
  ], 64))
}
}

})